import React, { useState } from "react"
import "./assets/styles/_index.scss"
import ShobaAboutSidebar from "./ShobaAboutSidebar"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"
import ProptvStand from "../../proptvstand/ProptvStand"

const videoUrlStand = "https://www.propverse.ai/utility/assets/sobha-developer/SobhaLimited.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")


const videoUrl ="https://www.propverse.ai/utility/assets/sobha-developer/SobhaLimited.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

function ShobaAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    centerMode: true,
                    centerPadding: "0px",
                    slidesToShow: 1,
                },
            },

        ],

    }

    const downloadBrochure = ()=>{
        const pdfUrl = '/utility/assets/sobha-developer/ShobaBrochure.pdf';
        
            const anchor = document.createElement('a');
            anchor.href = pdfUrl;
            anchor.download = 'ShobaBrochure.pdf';
            anchor.click();
      }

    return (
        <>

            <div className="ProjectAboutPageSectionSobha">
                <Container fluid className="BoothBackSection d-none d-md-none">
                    <Link to="/developers/abhee-developer/">
                        <ArrowLeft /> Back
                    </Link>

                    <div className="EnquiryBoothSection d-block d-md-none">
                        <Image src={enquiry} fluid className="" />
                    </div>
                </Container>
                <div className="d-none d-md-block">
                    <ShobaAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />


                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>
                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/sobha-developer/">
                                            <p><Image src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/sobha-developer/about/">
                                            <p><Image src={about} fluid className="" /> About&nbsp;Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <Image src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Sobha Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection">
                                            <h4 className='m-0'>Sobha Developers</h4>
                                            <p className="mb-0">
                                                <p className="AboutExpoPara">SOBHA epitomizes “passion at work” in totality. For us it is not only a catchphrase which sounds just right, rather we all strive to live it daily..</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">

                                                    <p className="AboutExpoPara">It serves as our compass which guides us towards creating world-class quality products and workmanship. It guides us to be transparent in all our dealings and adhere to delivery on time, each time.</p>

                                                    <p className="AboutExpoPara">We draw heavily from the innate qualities of our founder, Mr. PNC Menon in imbibing and actually living the brand. Simply put, this means that we do not cut corners toward making best–in–class products. Our work revolves around creating ‘quality’ with immense passion like Mr. Menon himself – the builder extraordinaire, who revolutionized the way people perceive quality in the realty sector.</p>

                                                    <p className="AboutExpoPara">Our track record of being the most reliable and trustworthy builder with a repertoire of award-winning properties across India speaks for itself. We changed the skyline in Bangalore, created landmark developments in Kerala, and have stepped into Delhi – NCR, Chennai, Coimbatore, Mysore, Pune, and many more to follow.</p>
                                                    <p className="AboutExpoPara">As we peep into the future, we remain firmly committed to creating urban living spaces where people can live, work and play smart.</p>
                                                    <p className="AboutExpoPara">We are acutely conscious of the fact that due to rapid urbanization, there are huge challenges and as responsible real estate players we shall continue to provide intelligent solutions in a manner that is sustainable. We rely on our people, their capacities to innovate, and their use of technology to create immense value for all our stakeholders. We will continue to raise the bar and set new standards in the industry to strengthen this goal of ours.</p>
                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button onClick={downloadBrochure}>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                             videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default ShobaAbout