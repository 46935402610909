import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function ShobaReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Sobha Developers</h4>

            <p className="mb-0">
                <p className="AboutExpoPara">SOBHA epitomizes “passion at work” in totality. For us it is not only a catchphrase which sounds just right, rather we all strive to live it daily. It serves as our compass which guides us towards creating world-class quality products and workmanship. It guides us to be transparent in all our dealings and adhere to delivery on time, each time.</p>

                <p className="AboutExpoPara">We draw heavily from the innate qualities of our founder, Mr. PNC Menon in imbibing and actually living the brand. Simply put, this means that we do not cut corners toward making best–in–class products. Our work revolves around creating ‘quality’ with immense passion like Mr. Menon himself – the builder extraordinaire, who revolutionized the way people perceive quality in the realty sector.</p>
            </p>

            {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">Our track record of being the most reliable and trustworthy builder with a repertoire of award-winning properties across India speaks for itself. We changed the skyline in Bangalore, created landmark developments in Kerala, and have stepped into Delhi – NCR, Chennai, Coimbatore, Mysore, Pune, and many more to follow.</p>
                    <p className="AboutExpoPara">As we peep into the future, we remain firmly committed to creating urban living spaces where people can live, work and play smart.</p>
                    <p className="AboutExpoPara">We are acutely conscious of the fact that due to rapid urbanization, there are huge challenges and as responsible real estate players we shall continue to provide intelligent solutions in a manner that is sustainable. We rely on our people, their capacities to innovate, and their use of technology to create immense value for all our stakeholders. We will continue to raise the bar and set new standards in the industry to strengthen this goal of ours.</p>

                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default ShobaReadMore;